//import './nirmalaFont'

import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { BiDownload } from 'react-icons/bi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  ChakraProvider,
  Divider,
  extendTheme,
  Flex,
  GridItem,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Skeleton,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import html2canvas from 'html2canvas'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as html2pdf from 'html2pdf.js'
import { jsPDF } from 'jspdf'
import { isArray, isEmpty, template } from 'lodash'
import moment from 'moment-timezone'
import simplur from 'simplur'

import { ActivityDto } from '~shared/types'

import { ADMINFORM_ROUTE } from '~constants/routes'
import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'

import { SecretKeyVerification } from '../../SecretKeyVerification'
import { useStorageResponsesContext } from '../../StorageResponsesContext'
import { StorageResponsesProvider } from '../../StorageResponsesProvider'

import { ConversationMessageModel } from './ConversationMessageModel'
import { DecryptedRow } from './DecryptedRow'
import { IndividualResponseNavbar } from './IndividualResponseNavbar'
import { useMutateDownloadAttachments } from './mutations'
import { PrintDecryptedRow } from './PrintDecryptedRow'
import {
  getSubmissionActivities,
  useFormAdmin,
  useIndividualSubmission,
  useSubmissionConversations,
  useSubmissionFormCollaborators,
  useSubmissionPayments,
} from './queries'
import { StatusChangeButtons } from './StatusChangeButtons'

const LoadingDecryption = memo(() => {
  return (
    <Stack spacing="1.5rem" divider={<StackDivider />}>
      <Skeleton h="2rem" maxW="20rem" mb="0.5rem" />
      <Stack>
        <Skeleton h="1.5rem" maxW="32rem" />
        <Skeleton h="1.5rem" maxW="5rem" />
      </Stack>
      <Stack>
        <Skeleton h="1.5rem" maxW="12rem" />
        <Skeleton h="1.5rem" maxW="5rem" />
      </Stack>
      <Stack>
        <Skeleton h="1.5rem" maxW="24rem" />
        <Skeleton h="1.5rem" maxW="3rem" />
      </Stack>
      <Box />
    </Stack>
  )
})

export const IndividualResponsePage = (): JSX.Element => {
  return (
    <StorageResponsesProvider>
      <IndividualResponse />
    </StorageResponsesProvider>
  )
}

const IndividualResponse = (): JSX.Element => {
  type activityTable = {
    rowNumber: number
    activityAt: string
    activityBy: string
    activityType: string
    reason: string
    note: string
  }

  const theme = extendTheme({
    fonts: {
      heading: `Inter`,
      body: `Inter`,
      mono: `Nirmala`,
    },
  })

  const newTemplate = `<div id="pdf-container">
  <style>
  table {
    border-collapse: collapse;
    width: 100%;
    padding-bottom: 20px;
  }
  
  th, td {
    border: 1px solid #172c51;
    padding-top: -1px;
    padding-bottom: 10px;
    padding-left: 5px;
    text-align: left;
    font-size: 12px;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  .answer-box {
    width: 100%;
    border: 1px solid #172c51;
    padding-top: -1px;
    padding-bottom: 10px;
    padding-left: 5px;
    text-align: left;
    font-size: 12px;
  }

  .question-box {
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 12px;
  }
  
  .singleQuestion {
    padding-bottom: 10px;
  }

  .component-container {
    break-inside: avoid;
  }

  @media print {
    .component-container {
      break-inside: avoid;
      page-break-inside: avoid;
    }
  }

  </style>
  <% _.forEach(answerArray, function(item) { %>
    <% if (item.fieldType === 'section') { %>
      <h2 class="singleQuestion component-container" style="font-weight: bold;"><%- item.question %></h2>
    <% } else if (item.fieldType === 'statement') { %>
      <p class="singleQuestion component-container" style="font-size: 12px;"><%- item?.description %></p>
    <% } else if (item.fieldType === 'table') { %>
      <div class="singleQuestion">
        <p class="question-box component-container"><%- item.questionNumber %>. <%- item.question %></p>
        <table>
          <thead>
            <tr class="component-container">
              <% _.forEach(item.columnTitles, function(title) { %>
                <th><%- title %></th>
              <% }); %>
            </tr>
          </thead>
          <tbody>
            <% _.forEach(item.answerArray, function(row) { %>
              <tr class="component-container">
                <% _.forEach(row, function(cell) { %>
                  <td><%- cell %></td>
                <% }); %>
              </tr>
            <% }); %>
          </tbody>
        </table>
      </div>
    <% } else if (item.fieldType === 'textarea') { %>
      <div class="singleQuestion textarea-answer">
        <p class="question-box component-container"><%- item.questionNumber %>. <%- item.question %></p>
        <p class="answer-box">
          <% if (item.answer) { %>
            <%- item.answer %>
          <% } else if (item.answerArray && item.answerArray.length) { %>
            <%- item.answerArray.join(', ') %>
          <% } else { %>
            -
          <% } %>
        </p>
      </div>
    <% } else { %>
      <div class="singleQuestion component-container">
        <p class="question-box"><%- item.questionNumber %>. <%- item.question %></p>
        <p class="answer-box">
          <% if (item.answer) { %>
            <%- item.answer %>
          <% } else if (item.answerArray && item.answerArray.length) { %>
            <%- item.answerArray.join(', ') %>
          <% } else { %>
            -
          <% } %>
        </p>
      </div>
    <% } %>
  <% }); %>
</div>`

  const formatDate = (value: string): string => {
    const date = new Date(value)
    const day = date.toLocaleString('default', { day: '2-digit' })
    const month = date.toLocaleString('default', { month: 'short' })
    const year = date.toLocaleString('default', { year: 'numeric' })
    const time = date.toLocaleTimeString()
    return day + '/' + month + '/' + year + ' ' + time
  }

  const { formId, submissionId } = useParams()
  const toast = useToast()
  if (!formId) throw new Error('Missing formId')
  if (!submissionId) throw new Error('Missing submissionId')
  const navigate = useNavigate()
  const location = useLocation()
  const passedSecretKey: string = location.state?.currentSecretKey
  const { secretKey } = useStorageResponsesContext()
  const { data, isLoading, isError, refetch } =
    useIndividualSubmission(passedSecretKey)
  const submissionPayments = useSubmissionPayments()
  const submissionConversations = useSubmissionConversations(submissionId)
  const submissionFormCollaborators = useSubmissionFormCollaborators(formId)
  const formAdmin = useFormAdmin(formId)
  const [userCategory, setUserCategory] = useState<string>('AGENCY')
  const [signatureImage, setSignatureImage] = useState<string>('')
  const [activityTableData, setActivityTableData] = useState<activityTable[]>(
    [],
  )

  const conversations = isArray(submissionConversations?.data)
    ? submissionConversations?.data
    : []

  const formCollaborators = isArray(submissionFormCollaborators?.data)
    ? submissionFormCollaborators?.data
    : []

  const lastPaymentStatus = submissionPayments?.data
    ? submissionPayments?.data[0]?.paymentStatus
    : 'NO_STATUS'

  const lastTransactionId = submissionPayments?.data
    ? submissionPayments?.data[0]?._id
    : 'NOT_FOUND'

  const attachmentDownloadUrls = useMemo(() => {
    const attachmentDownloadUrls = new Map()
    data?.responses.forEach(({ questionNumber, downloadUrl, answer }) => {
      if (!questionNumber || !downloadUrl || !answer) return
      attachmentDownloadUrls.set(questionNumber, {
        url: downloadUrl,
        filename: answer,
      })
    })
    return attachmentDownloadUrls
  }, [data?.responses])

  const { downloadAttachmentsAsZipMutation } = useMutateDownloadAttachments()

  const forceUpdateReducer = (i: number) => i + 1

  const useForceUpdate = () => {
    const [, forceUpdate] = useReducer(forceUpdateReducer, 0)
    return forceUpdate
  }

  const forceUpdate = useForceUpdate()

  const getSubmissionActivitiesData = async () => {
    ApiService.get(`/activity/?submissionId=${submissionId}`)
      .then((data: any) => {
        if (data?.status === 200) {
          if (Array.isArray(data?.data?.activity)) {
            setActivityTableData(
              data?.data?.activity.map((value: ActivityDto, index: number) => {
                return {
                  rowNumber: index + 1,
                  activityAt: moment(value?.createdAt).format(
                    'DD MMM, YYYY HH:mm',
                  ),
                  activityBy: value?.executedBy?.username,
                  activityType: value?.activityType,
                  reason:
                    value?.data?.submission?.reason !== undefined
                      ? value?.data?.submission?.reason
                      : '',
                  note:
                    value?.data?.submission?.note !== undefined
                      ? value?.data?.submission?.note
                      : '',
                }
              }),
            )
          }
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    const user = localStorage.getItem('user')
    const userData = user ? JSON.parse(user)._doc : {}
    setUserCategory(userData.userCategory.code)
    getSubmissionActivitiesData()
  }, [])

  useEffect(() => {
    if (data?.signature) {
      setSignatureImage(data.signature?.value)
    }
  }, [data])

  const handleDownload = useCallback(() => {
    // if (attachmentDownloadUrls.size === 0 || !secretKey || !passedSecretKey)
    //   return
    const key = secretKey ? secretKey : passedSecretKey
    if (attachmentDownloadUrls.size === 0 || !key) return
    return downloadAttachmentsAsZipMutation.mutate({
      attachmentDownloadUrls,
      secretKey: key,
      fileName: `RefNo ${submissionId}.zip`,
    })
  }, [
    attachmentDownloadUrls,
    secretKey,
    downloadAttachmentsAsZipMutation,
    submissionId,
    passedSecretKey,
  ])

  const onStatusChange = useCallback(() => {
    const key = secretKey ? secretKey : passedSecretKey
    refetch()

    navigate(`${ADMINFORM_ROUTE}/${formId}/results`, {
      state: { currentSecretKey: key },
    })
  }, [formId, navigate, passedSecretKey, refetch, secretKey])

  const form = data?.formName

  const formSave = form?.substring(0, 30)
  const citizenName = data?.submittedByWithoutTitle.value

  const downloadPDF = async () => {
    const serialNo = data?.serialNumber
    const content = document.getElementById('divToPrint')
    if (content !== null) {
      if (serialNo) {
        const textElement = document.createElement('div')
        textElement.textContent = serialNo

        // Set styles for the text element
        textElement.style.position = 'absolute'
        textElement.style.right = '30px'
        textElement.style.top = '5px'
        textElement.style.fontFamily = 'Arial'
        textElement.style.fontSize = '14px'

        // Append the text element to the content container
        content.appendChild(textElement)
      }

      content.style.display = 'block'

      const options = {
        margin: 6,
        filename: `${formSave} - ${citizenName}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        // html2canvas: { scale: 2 },
        // jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        // pagebreak: { before: '.allContent', after: ['.header', '.footer'] },
        // beforeRender: addHeaderFooter,
      }

      await html2pdf(content, options)

      content.style.display = 'none'
    }
  }

  const compiledTemplate = template(newTemplate)

  const printResult = data?.responses
    ? compiledTemplate({ answerArray: data?.responses })
    : ''

  if (!secretKey && !passedSecretKey) return <SecretKeyVerification />

  return (
    // <Flex flexDir="column" marginTop={{ base: '-1.5rem', md: '-3rem' }}>
    <Flex flexDir="column">
      {/* <IndividualResponseNavbar /> */}
      <Box height="40px">
        <Heading as="h4" size="md">
          View Form Submission
        </Heading>
      </Box>

      <Stack
        px={{ md: '1.75rem', lg: '2rem' }}
        spacing={{ base: '1.5rem', md: '2.5rem' }}
      >
        <Stack>
          <Stack
            bg="primary.100"
            p="1.5rem"
            sx={{
              fontFeatureSettings: "'tnum' on, 'lnum' on, 'zero' on, 'cv05' on",
            }}
          >
            {attachmentDownloadUrls.size > 0 && (
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text
                  as="span"
                  textStyle="subhead-1"
                  py={{ base: '0', md: '0.25rem' }}
                >
                  Attachments:
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  <Button
                    variant="link"
                    isDisabled={downloadAttachmentsAsZipMutation.isLoading}
                    onClick={handleDownload}
                    rightIcon={
                      downloadAttachmentsAsZipMutation.isLoading ? (
                        <Spinner fontSize="1.5rem" />
                      ) : (
                        <BiDownload fontSize="1.5rem" />
                      )
                    }
                  >
                    {simplur`Download ${attachmentDownloadUrls.size} attachment[|s] as .zip`}
                  </Button>
                </Skeleton>
              </Stack>
            )}
          </Stack>

          <Box display="none" id="divToPrint">
            <ChakraProvider>
              <Box p="0 25px">
                <Box w="100%" pb={2} mt={2} borderBottom="2px solid #4472C4">
                  <Center>
                    <Heading as="h5" size="md" my={2} textAlign="center">
                      {form}
                    </Heading>
                  </Center>
                  <Center>
                    <Text mb={4}>{data?.agencyName ?? '-'}</Text>
                  </Center>
                </Box>
              </Box>
              <Box p="5px 25px" w="100%">
                {/* <Stack spacing="0.4rem">
                  {data?.responses.map((r, idx) => (
                    <PrintDecryptedRow
                      row={r}
                      secretKey={secretKey ? secretKey : passedSecretKey}
                      key={idx}
                    />
                  ))}
                  <Box />
                </Stack> */}
                {data?.responses ? (
                  <div dangerouslySetInnerHTML={{ __html: printResult }} />
                ) : null}

                {signatureImage !== '' ? (
                  <Box
                    w="100%"
                    mt={10}
                    pt={2}
                    borderTop="2px solid #4472C4"
                    className="component-container"
                  >
                    <Stack
                      p="1.5rem"
                      w="100%"
                      align="stretch"
                      justify="center"
                      direction={'row'}
                      spacing={6}
                    >
                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        align="center"
                        justify="center"
                        direction={{ base: 'column', md: 'row' }}
                        borderRight="2px dashed #4472C4"
                        pr={4}
                        minWidth="60%"
                        maxWidth="75%"
                        minH="150px"
                      >
                        <Text
                          as="span"
                          textStyle="subhead-1"
                          fontSize="8pt"
                          fontWeight="bold"
                        >
                          Signature
                        </Text>
                        <Center
                          bg={'none'}
                          minH="70px"
                          maxH="140px"
                          minW="140px"
                          maxW="350px"
                          borderWidth="1px"
                          borderColor="gray.400"
                          borderStyle="dashed"
                          borderRadius="12px"
                        >
                          <Image
                            minW="120px"
                            maxW="390px"
                            maxH="160px"
                            p={2}
                            objectFit="scale-down"
                            src={signatureImage}
                          />
                        </Center>
                      </Stack>
                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        justify="center"
                        direction="column"
                      >
                        <Text
                          as="span"
                          textStyle="subhead-1"
                          fontSize="8pt"
                          fontWeight="bold"
                        >
                          Signed Date:
                        </Text>
                        <Text fontSize="8pt" maxW="30rem">
                          {data?.submissionTime ?? 'N/A'}
                        </Text>
                      </Stack>
                    </Stack>
                  </Box>
                ) : null}
              </Box>
            </ChakraProvider>
          </Box>

          <Box>
            <ChakraProvider theme={theme}>
              <Stack ml={10}>
                {/* <Stack>
                <Box height="35px">
                  <Heading as="h5" size="md">
                    Submission content
                  </Heading>
                </Box>
              </Stack> */}{' '}
                <Heading as="h5" size="md">
                  {form}
                </Heading>
                {isLoading || isError ? (
                  <LoadingDecryption />
                ) : (
                  <Stack spacing="0.2rem">
                    {data?.responses.map((r, idx) => (
                      <DecryptedRow
                        row={r}
                        secretKey={secretKey ? secretKey : passedSecretKey}
                        key={idx}
                      />
                    ))}
                    <Box />
                    {signatureImage !== '' ? (
                      <Stack p="1.5rem" mt={10}>
                        <Stack
                          spacing={{ base: '0', md: '0.5rem' }}
                          direction={{ base: 'column', md: 'row' }}
                        >
                          <Text
                            as="span"
                            textStyle="subhead-1"
                            fontSize="0.8rem"
                            fontWeight="bold"
                          >
                            Signature:
                          </Text>
                          <Center
                            bg={'none'}
                            h="140px"
                            w="350px"
                            borderWidth="1px"
                            borderColor="gray.400"
                            borderStyle="dashed"
                            borderRadius="12px"
                          >
                            <Image
                              h="100px"
                              w="320px"
                              // m={'2px'}
                              objectFit="contain"
                              src={signatureImage}
                            />
                          </Center>
                        </Stack>
                        <Stack
                          spacing={{ base: '0', md: '0.5rem' }}
                          direction={{ base: 'column', md: 'row' }}
                        >
                          <Text
                            as="span"
                            textStyle="subhead-1"
                            fontSize="0.8rem"
                            fontWeight="bold"
                          >
                            Signed Date:
                          </Text>
                          <Text
                            fontSize="0.7rem"
                            maxW="30rem"
                            pb="5px"
                            borderWidth="1px"
                            borderColor="gray.400"
                            borderStyle="hidden hidden dashed hidden"
                          >
                            {data?.submissionTime ?? 'N/A'}
                          </Text>
                        </Stack>
                      </Stack>
                    ) : null}
                  </Stack>
                )}
              </Stack>
            </ChakraProvider>
          </Box>

          <Stack
            bg="primary.100"
            p="1.5rem"
            sx={{
              fontFeatureSettings: "'tnum' on, 'lnum' on, 'zero' on, 'cv05' on",
            }}
          >
            <Box>
              <Text textStyle="h4">Form Submission Details</Text>
            </Box>

            <Stack pl="1.5rem">
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  Submission ID:
                </Text>
                <Text>{submissionId}</Text>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  Serial Number:
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.serialNumber ?? '-'}
                </Skeleton>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  Created at:
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.submissionTime
                    ? formatDate(data.submissionTime.toString())
                    : '-'}
                </Skeleton>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  Submitted at:
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.submissionTime
                    ? formatDate(data.submissionTime.toString())
                    : '-'}
                </Skeleton>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  Submitted by:
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.submittedBy.value ?? '-'}
                </Skeleton>
              </Stack>

              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  Submission Status:
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.submissionStatus ?? '-'}
                </Skeleton>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  Gov. Agency Name:
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.agencyName ?? '-'}
                </Skeleton>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  Form Name:
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.formName ?? '-'}
                </Skeleton>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  Form Description:
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.formDescription ?? '-'}
                </Skeleton>
              </Stack>

              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  Form Language:
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.formLanguage ?? '-'}
                </Skeleton>
              </Stack>

              {data?.submissionStatus === 'Inprogress' ? (
                <>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Accepted at:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.acceptedAt
                        ? formatDate(data.acceptedAt.toString())
                        : '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Accepted by:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.acceptedBy.value ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Accepted Note:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.acceptedAt
                        ? formatDate(data.acceptedAt.toString())
                        : '-'}
                    </Skeleton>
                  </Stack>
                </>
              ) : null}

              {data?.submissionStatus === 'Completed' ? (
                <>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Accepted at:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.acceptedAt
                        ? formatDate(data.acceptedAt.toString())
                        : '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Accepted by:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.acceptedBy.value ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Accepted Note:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.acceptedNote ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Completed at:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.completedAt
                        ? formatDate(data.completedAt.toString())
                        : '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Completed by:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.completedBy.value ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Completed Note:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.completedNote ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Completed Attachment:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.completedAttachment ? (
                        <Link
                          href={`${data.completedAttachment}`}
                          download="Completed Attachment"
                        >
                          Click to download
                        </Link>
                      ) : (
                        '-'
                      )}
                    </Skeleton>
                  </Stack>
                </>
              ) : null}

              {data?.submissionStatus === 'Rejected' ? (
                <>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Rejected at:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.rejectedAt
                        ? formatDate(data?.rejectedAt.toString())
                        : '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Rejected by:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.rejectedBy.value ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Rejected Reason:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.rejectedReason ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Rejected Note:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.rejectedNote ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Rejected Attachment:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.rejectedAttachment ? (
                        <Link
                          href={`${data.rejectedAttachment}`}
                          download="Rejected Attachment"
                        >
                          Click to download
                        </Link>
                      ) : (
                        '-'
                      )}
                    </Skeleton>
                  </Stack>
                </>
              ) : null}
            </Stack>

            {data?.paymentRequired ? (
              <>
                <Stack
                  bg="primary.100"
                  p="1.5rem"
                  sx={{
                    fontFeatureSettings:
                      "'tnum' on, 'lnum' on, 'zero' on, 'cv05' on",
                  }}
                >
                  <Text textStyle="h4">Payment Details</Text>

                  {submissionPayments?.data?.length === 0 ? (
                    <Text pl="1.5rem">No Payments</Text>
                  ) : null}

                  {submissionPayments?.data?.map((item, index) => (
                    <Stack pl="1.5rem">
                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        direction={{ base: 'column', md: 'row' }}
                      >
                        <Text as="span" textStyle="subhead-1">
                          Payment at:
                        </Text>
                        <Text>{item?.created}</Text>
                      </Stack>
                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        direction={{ base: 'column', md: 'row' }}
                      >
                        <Text as="span" textStyle="subhead-1">
                          Payment Status:
                        </Text>
                        <Text>{item?.paymentStatus}</Text>
                      </Stack>

                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        direction={{ base: 'column', md: 'row' }}
                      >
                        <Text as="span" textStyle="subhead-1">
                          Payment Amount:
                        </Text>
                        {/* <Stack
                        <Text>{item?.paymentMerchantId}</Text>
                      </Stack> */}

                        <Text>{item?.paymentAmount}</Text>
                      </Stack>

                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        direction={{ base: 'column', md: 'row' }}
                      >
                        <Text as="span" textStyle="subhead-1">
                          Payment Type:
                        </Text>
                        <Text>{item?.paymentType?.paymentTypeName}</Text>
                      </Stack>
                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        direction={{ base: 'column', md: 'row' }}
                      >
                        <Text as="span" textStyle="subhead-1">
                          Payment Transaction ID:
                        </Text>
                        <Text>{item?.paymentTxnId}</Text>
                      </Stack>

                      <Accordion allowToggle>
                        <AccordionItem>
                          <AccordionButton>
                            <Box flex="1" textAlign="left">
                              More
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel pb={4}>
                            <Stack
                              spacing={{ base: '0', md: '0.5rem' }}
                              direction={{ base: 'column', md: 'row' }}
                            >
                              <Text as="span" textStyle="subhead-1">
                                Payment Transaction Request:
                              </Text>
                              <Text>{item?.paymentTxnRequest}</Text>
                            </Stack>
                            <Stack
                              spacing={{ base: '0', md: '0.5rem' }}
                              direction={{ base: 'column', md: 'row' }}
                            >
                              <Text as="span" textStyle="subhead-1">
                                Payment Transaction Response:
                              </Text>
                              <Text>{item?.paymentTxnResponse}</Text>
                            </Stack>
                            {/* <Stack
                              spacing={{ base: '0', md: '0.5rem' }}
                              direction={{ base: 'column', md: 'row' }}
                            >
                              <Text as="span" textStyle="subhead-1">
                                Payment Merchant ID:
                              </Text>
                              <Text>{item?.paymentMerchantId}</Text>
                            </Stack> */}
                            <Stack
                              spacing={{ base: '0', md: '0.5rem' }}
                              direction={{ base: 'column', md: 'row' }}
                            >
                              <Text as="span" textStyle="subhead-1">
                                Payment Service ID:
                              </Text>
                              <Text>{item?.paymentServiceId}</Text>
                            </Stack>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                      <Divider p="1.5rem" />
                    </Stack>
                  ))}
                </Stack>

                <Stack
                  bg="primary.100"
                  pl="3rem"
                  sx={{
                    fontFeatureSettings:
                      "'tnum' on, 'lnum' on, 'zero' on, 'cv05' on",
                  }}
                >
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Payment Refunded:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.paymentRefunded ? 'True' : 'False'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Payment Refunded at:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.paymentRefundedAt ?? 'N/A'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Payment Refunded by:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.paymentRefundedBy?.value ?? 'N/A'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      Payment Refunded Note:
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.paymentRefundedNote ?? 'N/A'}
                    </Skeleton>
                  </Stack>
                </Stack>
              </>
            ) : null}

            <Stack p="1.5rem">
              <Text textStyle="h4">Activities</Text>

              <Box
                bg={'white'}
                px={10}
                py={5}
                overflowX="auto"
                maxWidth="100Vw"
                borderRadius="12px"
              >
                <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
                  <Box></Box>
                </SimpleGrid>
                <SimpleGrid
                  columns={{ sm: 1, md: 1 }}
                  spacingX="70px"
                  spacingY="20px"
                >
                  <DataTable
                    heading=""
                    rowValues={activityTableData ? activityTableData : []}
                    cols={[
                      {
                        Header: 'No',
                        accessor: 'rowNumber',
                        disableSortBy: true,
                        maxWidth: 50,
                        minWidth: 25,
                      },
                      {
                        Header: 'Activity At',
                        accessor: 'activityAt',
                        disableSortBy: true,
                        maxWidth: 100,
                        minWidth: 70,
                      },
                      {
                        Header: 'Activity By',
                        accessor: 'activityBy',
                        disableSortBy: true,
                        maxWidth: 100,
                        minWidth: 70,
                      },
                      {
                        Header: 'Activity Type',
                        accessor: 'activityType',
                        disableSortBy: true,
                        maxWidth: 100,
                        minWidth: 70,
                      },
                      {
                        Header: 'Reason',
                        accessor: 'reason',
                        disableSortBy: true,
                        maxWidth: 400,
                        minWidth: 50,
                      },
                      {
                        Header: 'Note',
                        accessor: 'note',
                        disableSortBy: true,
                        maxWidth: 400,
                        minWidth: 50,
                      },
                    ]}
                  />
                  {isLoading ? (
                    <Center>
                      <Spinner />
                    </Center>
                  ) : null}
                  {activityTableData.length === 0 ? (
                    <Center>No results found</Center>
                  ) : null}
                </SimpleGrid>
              </Box>
            </Stack>
          </Stack>

          {/* Load conversation history */}
          <Stack bg="primary.100" p="1.5rem">
            <Text textStyle="h4">Conversation</Text>
            {isEmpty(conversations) ? (
              <Text pt="10px" pl="1.5rem">
                No conversation to show
              </Text>
            ) : (
              conversations.map((conversation, i) => {
                return (
                  <Box key={i} pl="1.5rem">
                    <SimpleGrid
                      columns={{ sm: 1, md: 4 }}
                      spacing="5px"
                      pt={i === 0 ? '10px' : '40px'}
                    >
                      <GridItem>
                        <Text
                          as="span"
                          textStyle="subhead-1"
                          style={{ fontWeight: 700 }}
                        >
                          {conversation?.conversationType?.code === 'CQ'
                            ? 'Inquiry:'
                            : 'Message:'}
                        </Text>
                      </GridItem>
                      <GridItem>
                        {conversation?.conversationType?.code === 'CQ'
                          ? conversation?.senderPublicUser?.name
                          : conversation?.agency?.name}
                      </GridItem>
                      {/* By assuming the server time is in Sri Lankan time zone */}
                      <GridItem>
                        {moment(conversation?.createdAt).format('DD/MM/YY')}
                      </GridItem>
                      <GridItem>
                        {moment(conversation?.createdAt).format('HH:mm')}
                      </GridItem>
                    </SimpleGrid>
                    <SimpleGrid columns={{ sm: 1, md: 4 }} spacing="5px" pt={2}>
                      <GridItem>
                        <Text as="span" textStyle="subhead-1">
                          Message Type:
                        </Text>
                      </GridItem>
                      <GridItem colSpan={3}>
                        {conversation?.message?.type}
                      </GridItem>
                    </SimpleGrid>
                    <SimpleGrid columns={{ sm: 1, md: 4 }} spacing="5px" pt={2}>
                      <GridItem>
                        <Text as="span" textStyle="subhead-1">
                          Message:
                        </Text>
                      </GridItem>
                      <GridItem colSpan={3}>
                        {conversation?.message?.content}
                      </GridItem>
                    </SimpleGrid>
                  </Box>
                )
              })
            )}
            {/* Only show for relevant status and collaborator */}
            <SimpleGrid columns={{ sm: 1, md: 4 }} spacing="5px" pt="10px">
              <GridItem colSpan={3}></GridItem>
              <GridItem>
                <ConversationMessageModel
                  formId={formId}
                  agencyId={data?.agencyId}
                  agencyName={data?.agencyName}
                  submissionId={submissionId}
                  // formCollaborators={formCollaborators}
                  // submissionStatus={data?.submissionStatus}
                  formAdmin={formAdmin?.data?.form?.admin?._id}
                  isNoConversation={isEmpty(conversations)}
                />
              </GridItem>
            </SimpleGrid>
          </Stack>

          {signatureImage !== '' ? (
            <Stack bg="primary.100" p="1.5rem" mt={10}>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  Signature:
                </Text>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Center
                  bg={'none'}
                  h="154px"
                  w="424px"
                  borderWidth="1px"
                  borderColor="gray.400"
                  borderStyle="dashed"
                  borderRadius="12px"
                >
                  <Image
                    h="150px"
                    w="420px"
                    // m={'2px'}
                    objectFit="contain"
                    src={signatureImage}
                  />
                </Center>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  Signed Date:
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.submissionTime ?? 'N/A'}
                </Skeleton>
              </Stack>
            </Stack>
          ) : null}

          <Stack bg="primary.100" p="1.5rem">
            <StatusChangeButtons
              status={data?.submissionStatus}
              formId={formId}
              formName={data?.formName}
              submissionId={submissionId}
              paymentRefunded={data?.paymentRefunded}
              agencyId={data?.agencyId}
              lastPaymentStatus={lastPaymentStatus}
              submittedAt={data?.submissionTime}
              submittedBy={data?.submittedBy?.value}
              paymentAmount={data?.paymentAmount}
              lastPaymentTransactionId={lastTransactionId}
              download={downloadPDF}
              secretKey={secretKey ? secretKey : passedSecretKey}
              onStatusChange={onStatusChange}
            />
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  )
}
